<template>
  <common-dialog
    :show="show"
    :loading="loading"
    @close="close"
  >
    <template #header>
      <span>Добавление платежного поручения</span>
    </template>
    <template #body>
      <payment-order-form
        v-model="state"
        :banks="banks"
        :owner-identity.sync="ownerIdentity"
        :is-individual="isIndividual"
        :available-payment-modes="availablePaymentModes"
        :payment-types="paymentTypes"
        :currencies="currencies"
        :disabled-fields="['currency_letter', 'code', 'owner_identity']"
      />
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-5">
        <v-btn
          elevation="0"
          color="#EDEDED"
          class="mr-2"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          elevation="0"
          @click="submit"
        >
          Добавить
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import moment from "moment";
import {OPEN_DEBIT_MODAL} from "@/components/dt/graph-b/events";
import {BAD_REQUEST_ERROR} from "@/constants/messages";
import {
  getAvailablePaymentModes, isValidPaymentOrderForm,
  paymentOrderDefaultState
} from "@/helpers/payment-order";
import PaymentOrderForm from "@/components/forms/payment-order-form.vue";
import CommonDialog from "@/components/shared/common-dialog.vue";

export default {
  components: {CommonDialog, PaymentOrderForm},
  mixins: [closeOnEscapeMixin, filterBySearchFieldMixin],
  props: {
    show: {
      required: true,
      type: Boolean
    },
    params: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      banks: [],
      paymentTypes: [],
      state: paymentOrderDefaultState(),
      ownerIdentity: null
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      user: "auth/getUser",
      currencies: "catalogs/getNsiCurrencies",
      paymentModes: "catalogs/getNsiPaymentModes"
    }),
    isIndividual() {
      return !!this.selected.declarant.declarant_json.individual
    },
    owner() {
      if (this.isIndividual) {
        return this.selected.declarant.declarant_json.personal_number
      }
      return this.selected.declarant.declarant_inn
    },
    remains() {
      return this.state.remains ?? 0
    },
    availablePaymentModes() {
      return this.getAvailablePaymentModes(this.paymentModes)
    }
  },
  created() {
    this.setState()
    this.loadBanks()
    this.fetchNsiPaymentTypes()
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
    setState() {
      const {currency_letter, code} = this.params.item ?? {}
      const sum = this.params.remains
      const payment_date = moment().format("YYYY-MM-DD")
      const payment_type = "БН"
      this.state = {
        ...this.state,
        currency_letter,
        code,
        sum,
        payment_date,
        payment_type
      }
      this.setOwnerIdentity()
    },
    setOwnerIdentity() {
      this.ownerIdentity = this.owner
    },
    loadBanks() {
      this.$store.dispatch('catalogs/loadBankBIC').then((banks) => this.banks = banks)
    },
    getAvailablePaymentModes,
    fetchNsiPaymentTypes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.paymentTypes = res.data.list.map((i) => ({...i, search: `${i.code}-${i.name}`}))
      })
    },
    getOwnerName() {
      if (this.isIndividual) {
        const {
          declarant_last_name,
          declarant_first_name,
          declarant_middle_name
        } = this.selected.declarant
        return [declarant_last_name, declarant_first_name, declarant_middle_name].filter((v) => v).join(" ").trim()
      }
      return this.selected.declarant.declarant_name
    },
    submit() {
      if (!isValidPaymentOrderForm.call(this, {
        paymentOrderState: this.state,
        ownerIdentity: this.ownerIdentity
      })) return;
      const payload = convertEmptyStringsToNull({
        ...this.state,
        unp: this.isIndividual ? null : this.selected.declarant.declarant_inn,
        personal_number: this.isIndividual ? this.selected.declarant.declarant_json.personal_number : null,
        remains: this.state.sum,
        division_id: this.user.user.activeDivisionId,
        owner_name: this.getOwnerName()
      })
      this.setLoading(true)
      this.$store.dispatch('dt/createPaymentOrder', payload)
        .then(({data}) => {
          this.$info("ПП создано")
          this.$emit("action", {
            action: OPEN_DEBIT_MODAL,
            params: {
              ...this.params,
              selectedPaymentOrderId: data.id
            }
          })
        })
        .catch(this.showErrorMessage)
        .finally(() => this.setLoading(false))
    },
    showErrorMessage(err) {
      const message = +err.response.status === 400 && err.response.data?.error !== BAD_REQUEST_ERROR
        ? err.response.data
        : "Не удалось создать ПП"
      this.$error(message)
    },
    clearState() {
      Object.assign(this.$data.state, paymentOrderDefaultState())
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>
